// .firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBfzkG3b0u_wktGoF-VWejX_N93H-z-r90",

  authDomain: "qrdrly-landing.firebaseapp.com",

  databaseURL: "https://qrdrly-landing-default-rtdb.firebaseio.com",

  projectId: "qrdrly-landing",

  storageBucket: "qrdrly-landing.appspot.com",

  messagingSenderId: "858055423909",

  appId: "1:858055423909:web:2efe084d23b9787f215a4c",
  
  measurementId: "G-WTPCS128MF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { database };
