import React from 'react'
import './Section.css'


export default function SectionThree() {
  return (
    <div className='section-container'>
            <div className='header-container'>
                <h2>Step 3: Instant Menu Updates</h2>
                <p>Take control of your menu with real-time updates. Quickly inform customers when items are unavailable or if your store is offline, ensuring a smooth experience from start to finish.</p>
            </div>
            <div className='image-container'>
                <img className='section-image ipad' src={`${process.env.PUBLIC_URL}/assets/manageDashboard.png`} alt='' />
            </div>
    </div>
  )
}
