import React from 'react';
import Slider from 'react-slick';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './Section.css';

export default function SectionOne() {
  // Add more image URLs here
  const images = [
    `${process.env.PUBLIC_URL}/assets/appOrder1.png`,
    `${process.env.PUBLIC_URL}/assets/appOrder2.png`,
    `${process.env.PUBLIC_URL}/assets/appOrder3.png`,
  
  ];

  // Custom Next Arrow
  const NextArrow = ({ onClick }) => (
    <div className="arrow next" onClick={onClick}>
      <FaArrowRight />
    </div>
  );

  // Custom Previous Arrow
  const PrevArrow = ({ onClick }) => (
    <div className="arrow prev" onClick={onClick}>
      <FaArrowLeft />
    </div>
  );

  const settings = {
    dots: false, // Hide dots for a cleaner look
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Automatically slide
    autoplaySpeed: 3000, // 3 seconds interval
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className='section-container'>
      <div className='header-container'>
        <h2>Step 1: Scan, Order, Pay</h2>
        <p>
          With a quick scan of your QR code, customers can browse your menu,
          place their order, and pay—all without waiting in line. Elevate their
          dining experience with seamless ordering.
        </p>
      </div>

      <Slider {...settings} className='slider'>
        {images.map((image, index) => (
          <div key={index} className="slider-item">
            <img className='section-image' src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
