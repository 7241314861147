import React from 'react'
import './Section.css'


export default function SectionTwo() {
  return (
    <div className='section-container alt'>
            <div className='header-container'>
                <h2>Step 2: Effortlessly Manage Orders</h2>
                <p>Keep your orders organised with our easy-to-use dashboard. Get real-time notifications and let your customers know when their food is ready—saving time for both you and them.</p>
            </div>
            <div className='image-container'>
                <img className='section-image ipad' src={`${process.env.PUBLIC_URL}/assets/manageOrders.png`} alt='' />
            </div>
    </div>
  )
}
