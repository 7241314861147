import React from 'react'
import './Nav.css'
import { IoQrCodeSharp } from "react-icons/io5";

export default function Nav() {
  return (
    <nav className="nav">
      <div className="logo">
        <IoQrCodeSharp size={24} /><h1>QRdrly</h1> 
      </div>
      
    </nav>
  )
}
