import logo from './logo.svg';
import './App.css';
import './styles/variables.css'
import Hero from './components/Hero';
import SectionOne from './components/SectionOne'
import SectionTwo from './components/SectionTwo';
import SectionThree from './components/SectionThree';
import Inquire from './components/Inquire';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




function App() {
  return (
    <div className="App">
      
      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Inquire />
      
    </div>
  );
}

export default App;
