import React, { useState } from 'react'
import { ref, set } from 'firebase/database';
import { database } from '../firebase'; // Adjust the path as needed
import './Inquire.css'

export default function Inquire() {
    const [submitted, setSubmitted] = useState(false); // State to track form submission

    const handleSubmit = (e) => {
        e.preventDefault();
        const businessName = e.target.businessName.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value;
        setSubmitted(true)
    
        // Store inquiry in Firebase database
        const inquiryRef = ref(database, 'inquiries/' + email.replace('.', ','));
        set(inquiryRef, {
          businessName: businessName,
          email: email,
          phone: phone,
          timestamp: new Date().toISOString(),
        })
        .then(() => {
          console.log("Inquiry captured:", { businessName, email, phone });
          e.target.reset(); // Reset the form
        })
        .catch((error) => {
          console.error("Error capturing inquiry:", error);
        });
      };

  return (
    <div id='inquiry-form'>
        
      {!submitted ? (
        
        <form className="inquiry-form" onSubmit={handleSubmit}>
            <h2>Interested? Let's Chat</h2>
          <input
            type="text"
            name="businessName"
            className="input-field"
            placeholder="Business Name"
            required
          />
          <input
            type="email"
            name="email"
            className="input-field"
            placeholder="Email"
            required
          />
          <input
            type="tel"
            name="phone"
            className="input-field"
            placeholder="Phone Number"
            required
          />
          <button type="submit" className="submit-button">Submit Inquiry</button>
        </form>
      ) : (
        <div className="thank-you-message">
          <h2>Thank You!</h2>
          <p>Your inquiry has been submitted successfully. We will get back to you shortly!</p>
        </div>
      )}
    </div>
  );
}
