import React from 'react';
import './Hero.css';
import Nav from './Nav';  // Import the Nav component


const Hero = () => {

  return (
    <section className="hero">
      <Nav />  {/* Nav is now part of the hero */}
      <div className="hero-content">
        <div className="hero-text">
          <h1>Seamless Online Ordering for Your Food Business</h1>
          <p>Boost efficiency and customer satisfaction with our intuitive online ordering system, designed to save time and simplify the way you manage orders.</p>
          <a href="#inquiry-form" className="inquire-button">Inquire</a>
          
        </div>

        {/* Phone mockup */}
        <div className="hero-mockup">
          <img src={`${process.env.PUBLIC_URL}/assets/PhoneTabletHero.png`} alt="Phone mockup showing the app" className="mockup-svg" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
